import {
  Button,
  Checkbox,
  Container,
  FileInput,
  Input,
  Modal,
  Rating,
  Select,
  TextInput,
  Text,
  Textarea,
  Title,
  Loader,
  rem,
  Group,
  ActionIcon,
  Badge,
  TypographyStylesProvider,
  Skeleton,
  LoadingOverlay,
  Flex,
} from "@mantine/core";

import { format } from "date-fns";
import { Tasks } from "models/Task/Task";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import useOrderStore from "store/OrderStore";
import useUsersStore from "store/UserStore";
import UploadFile from "./UploadFile";
import { useNavigate, useParams } from "react-router-dom";
import { FormField } from "./../../../models/Task/Task";

import { IconX } from "@tabler/icons-react";

import MySlider from "components/Slider/MySlider";
import { Attachment } from "models/List/OrdersExecution";

import icon1 from "../../../img/icons/icon-doc.png";
interface FormFiles {
  [key: string]: File[];
}

const removeButton = (
  formId: string,
  fileId: string,
  setFormValues: any,
  formValues: any
) => (
  <ActionIcon
    size="xs"
    color="blue"
    radius="xl"
    variant="transparent"
    onClick={(event) => {
      event.stopPropagation();
      const updatedFiles = { ...formValues };

      if (updatedFiles[formId]) {
        updatedFiles[formId] = updatedFiles[formId].filter(
          (file: any) => file.id !== fileId
        );

        setFormValues({
          ...formValues,
          [formId]: updatedFiles[formId],
        });
      }
    }}
  >
    <IconX size={rem(10)} />
  </ActionIcon>
);

const Value: FC<{
  file: File;
  fileId: string;
  formId: string;
  setFormValues: any;
  formValues: any;
}> = ({ file, fileId, formId, setFormValues, formValues }) => {
  return (
    <div>
      <Badge
        variant="outline"
        pr={3}
        rightSection={removeButton(
          formId,
          fileId,

          setFormValues,
          formValues
        )} // Corrected argument order
      >
        {file.name}
      </Badge>
    </div>
  );
};

interface ValueComponentProps {
  value: File | File[] | null;
  formId: string;
  setFormValues: any;
  formValues: any;
}

export const ValueComponent: FC<ValueComponentProps> = ({
  formId,
  setFormValues,
  formValues,
}) => {
  const { formFiles, fileResponse } = useOrderStore();
  const updatedFileResponse = useOrderStore.getState().fileResponse;
  console.log("fileResponse", updatedFileResponse);

  const value = formValues[formId]; // Используем formValues[formId] вместо value

  if (Array.isArray(value)) {
    return (
      <Flex wrap="wrap" rowGap={6} columnGap="xs">
        {value.map((file, index) => (
          <Value
            file={file}
            fileId={file.id}
            formId={formId}
            key={index}
            setFormValues={setFormValues}
            formValues={formValues}
          />
        ))}
      </Flex>
    );
  }

  return (
    value && (
      <Value
        file={value}
        fileId="file"
        formId={formId}
        setFormValues={setFormValues}
        formValues={formValues}
      />
    )
  );
};

const TaskModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState<{
    [key: string]: string | File[] | Date | boolean;
  }>({});
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const [isFileLoading, setFileLoading] = useState<{ [key: string]: boolean }>(
    {}
  );
  const loader = <Loader size={20} />;

  const { getUsers, brokerUsers } = useUsersStore();
  const navigate = useNavigate();
  const {
    tasks,
    getOrdersTasks,
    selectedOrderId,
    fileResponse,
    resetFileResponse,
    setTaskComplete,
    taskError,
    setFormFiles,
    resetFormFiles,
    formFiles,
  } = useOrderStore();

  const {
    getTaskById,
    OneTask,
    isTaskModalOpen,
    setTaskModalOpen,
    resetOneTask,
    setMultipleFileResponse,
  } = useOrderStore();
  const { orderId, taskId } = useParams<{ orderId: string; taskId: string }>();
  useEffect(() => {
    const shouldFetchUsers = OneTask?.form?.fields?.some(
      (field) => field.type === "BROKER_USER"
    );
    const getUsersForBroker = async () => {
      await getUsers("BROKER");
    };
    if (shouldFetchUsers) {
      getUsersForBroker();
    }
  }, [OneTask]);
  const [isTaskLoading, setisTaskLoadig] = useState(false);

  useEffect(() => {
    if (orderId && taskId) {
      const fetchTask = async () => {
        setisTaskLoadig(true);
        //await getOrdersTasks(orderId);
        await getTaskById(orderId, taskId);

        setisTaskLoadig(false);
      };
      fetchTask();
    }
  }, [taskId]);
  useEffect(() => {
    const loadFormValuesFromSessionStorage = () => {
      const storedFormValues = sessionStorage.getItem("formValues");
      if (storedFormValues) {
        setFormValues(JSON.parse(storedFormValues));
      }
    };

    loadFormValuesFromSessionStorage();
  }, []);
  const itemsTask = useMemo(() => (tasks as Tasks)?.items ?? [], [tasks]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const requiredFields =
        (OneTask?.form?.fields &&
          OneTask.form.fields.filter((field: FormField) => field.required)) ||
        [];
      const fieldErrors: { [key: string]: boolean } = {};
      requiredFields.forEach((field) => {
        if (
          !formValues[field.id] ||
          (Array.isArray(formValues[field.id]) &&
            (formValues[field.id] as any[]).length < 1)
        ) {
          fieldErrors[field.id] = true;
        }
      });

      setErrors(fieldErrors);

      if (Object.keys(fieldErrors).length > 0) {
        return;
      }
      setIsLoading(true);

      try {
        const fieldValues: { fieldValues: { [key: string]: any } } = {
          fieldValues: {},
        };
        OneTask?.form.fields &&
          Array.isArray(OneTask.form.fields) &&
          OneTask.form.fields.forEach((field: any) => {
            const fieldValue = formValues[field.id];

            if (fieldValue !== undefined) {
              switch (field.type) {
                case "TEXT":
                case "TEXT_AREA":
                  fieldValues.fieldValues[field.id] = { text: fieldValue };
                  break;
                case "FILES":
                  fieldValues.fieldValues[field.id] = { files: fieldValue };

                  break;
                case "DATE":
                  fieldValues.fieldValues[field.id] = { date: fieldValue };
                  break;
                case "CHECK":
                  fieldValues.fieldValues[field.id] = { check: fieldValue };
                  break;
                case "BROKER_USER":
                  fieldValues.fieldValues[field.id] = { id: fieldValue };
                  break;
                case "EVALUATION":
                  fieldValues.fieldValues[field.id] = { text: fieldValue };
                  break;
                case "NUMBER":
                  fieldValues.fieldValues[field.id] = { text: fieldValue };
                  break;
                default:
                  break;
              }
            }
          });

        if (orderId && taskId && itemsTask.length > 0) {
          console.log("fieldValues", fieldValues);

          await useOrderStore
            .getState()
            .setTaskComplete(orderId, taskId, fieldValues);
        }

        if (taskError == null) {
          navigate(`/orders/${orderId}`);
        }
        setIsLoading(false);
        // setTaskModalOpen(false);
      } catch (error) {
        console.error((error as any).response.data.message);
      }
    },
    [
      OneTask,
      formValues,
      formFiles,
      orderId,
      taskId,
      itemsTask,
      taskError,
      navigate,
    ]
  );

  const icon = <UploadFile />;
  const renderFormField = useCallback(
    (field: any) => {
      const error = errors[field.id];

      switch (field.type) {
        case "TEXT":
          return (
            <TextInput
              key={field.id}
              label={field.name}
              description={field.description}
              placeholder={`${field.name}`}
              radius="md"
              error={error}
              value={formValues[field.id] as string}
              withAsterisk={field.required}
              onChange={(event) => {
                const value = event.target.value;
                setFormValues({ ...formValues, [field.id]: value });
                if (errors[field.id]) {
                  setErrors({ ...errors, [field.id]: false });
                }
              }}
            />
          );
        case "NUMBER":
          return (
            <TextInput
              key={field.id}
              label={field.name}
              description={field.description}
              placeholder={`${field.name}`}
              radius="md"
              error={error}
              value={formValues[field.id] as string}
              withAsterisk={field.required}
              onChange={(event) => {
                const value = event.target.value;

                if (/^\d*$/.test(value)) {
                  setFormValues({ ...formValues, [field.id]: value });
                  if (errors[field.id]) {
                    setErrors({ ...errors, [field.id]: false });
                  }
                }
              }}
            />
          );
        case "TEXT_AREA": {
          return (
            <Textarea
              key={field.id}
              label={field.name}
              description={field.description}
              placeholder={`${field.name}`}
              value={formValues[field.id] as string}
              radius="md"
              withAsterisk={field.required}
              error={error}
              onChange={(event) => {
                if (errors[field.id]) {
                  setErrors({ ...errors, [field.id]: false });
                }
                const value = event.target.value;
                setFormValues({ ...formValues, [field.id]: value });
              }}
            />
          );
        }
        case "FILES":
          return (
            <Input.Wrapper
              required={field.required}
              withAsterisk={field.required}
            >
              <FileInput
                key={field.id}
                label={field.name}
                withAsterisk={field.required}
                multiple
                error={error}
                radius="md"
                valueComponent={(props) =>
                  ValueComponent({
                    ...props,
                    formId: field.id,
                    setFormValues,
                    formValues,
                  })
                }
                description={field.description}
                placeholder="Выберите файл"
                value={formFiles && formFiles[field.id]!}
                leftSection={isFileLoading[field.id] ? loader : icon}
                onChange={async (files) => {
                  try {
                    // Создаем новый объект formFiles, который включает в себя уже существующие файлы и новые файлы
                    const filesObject = {
                      ...formFiles,
                      [field.id]: [...(formFiles[field.id] || []), ...files],
                    };

                    setFormFiles(filesObject); // Передаем объект с массивом файлов

                    setFileLoading({ ...isFileLoading, [field.id]: true });

                    await setMultipleFileResponse(files, field.id);

                    const updatedFileResponse =
                      useOrderStore.getState().fileResponse;
                    setFormValues({
                      ...formValues,
                      [field.id]: updatedFileResponse,
                    });

                    if (errors[field.id]) {
                      setErrors({ ...errors, [field.id]: false });
                    }
                  } catch (err) {
                    console.error(err);
                  } finally {
                    setFileLoading({ ...isFileLoading, [field.id]: false });
                    resetFileResponse();
                  }
                }}
              />
            </Input.Wrapper>
          );
        case "DATE":
          return (
            <Input.Wrapper
              withAsterisk={field.required}
              description={field.description}
              label={field.name}
            >
              <Input
                key={field.id}
                type="date"
                radius="md"
                error={error}
                onChange={(event) => {
                  const selectedDate = event.target.value;
                  const date = new Date(selectedDate);

                  if (!isNaN(date.getTime())) {
                    const utcDate = new Date(
                      Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                      )
                    );
                    const isoString = format(utcDate, "yyyy-MM-dd");
                    setFormValues({
                      ...formValues,
                      [field.id]: isoString,
                    });
                    if (errors[field.id]) {
                      setErrors({ ...errors, [field.id]: false });
                    }
                  }
                }}
              />
            </Input.Wrapper>
          );
        case "CHECK":
          return (
            <div className="mt-2">
              <Input.Wrapper
                error={!!error && "Это поле обязательно для заполнения"}
                withAsterisk={field.required}
              >
                <Checkbox
                  key={field.id}
                  error={error}
                  label={
                    <>
                      {field.name}{" "}
                      {field.required && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </>
                  }
                  description={field.description}
                  checked={formValues[field.id] as boolean}
                  onChange={(event) => {
                    const checked = event.currentTarget.checked;
                    setFormValues({ ...formValues, [field.id]: checked });
                    if (errors[field.id]) {
                      setErrors({ ...errors, [field.id]: false });
                    }
                  }}
                />
              </Input.Wrapper>
            </div>
          );
        case "EVALUATION":
          return (
            <div className="mt-2">
              <Input.Wrapper
                label={field.name}
                error={!!error && "Это поле обязательно для заполнения"}
                withAsterisk={field.required}
              >
                <Rating
                  size="lg"
                  onChange={(event) => {
                    const checked = event;
                    setFormValues({ ...formValues, [field.id]: checked });
                    if (errors[field.id]) {
                      setErrors({ ...errors, [field.id]: false });
                    }
                  }}
                />
              </Input.Wrapper>
            </div>
          );
        case "BROKER_USER":
          return (
            <div className="mt-2">
              <Select
                error={error}
                label={field.name}
                withAsterisk={field.required}
                radius="md"
                placeholder={field.name}
                searchable
                onChange={(event) => {
                  const checked = event;
                  setFormValues({ ...formValues, [field.id]: checked });
                  if (errors[field.id]) {
                    setErrors({ ...errors, [field.id]: false });
                  }
                }}
                data={brokerUsers?.map((user) => ({
                  value: user.id,
                  label: `${user.name} (${user.username})`,
                }))}
              />
            </div>
          );
        default:
          return null;
      }
    },
    [formValues, errors, brokerUsers, isFileLoading]
  );
  const documents = (item: any) => {
    const executions = Array.isArray(item) ? item : [item];

    return executions.flatMap((execution: { attachments?: Attachment[] }) =>
      (execution.attachments || [])
        .filter(
          (attachment) =>
            attachment.content.type.startsWith("application") ||
            attachment.content.type.startsWith("text")
        )
        .map((attachment: Attachment) => {
          const documentType = attachment.content.type;

          let icon = icon1;
          if (documentType.startsWith("application/pdf")) {
            icon = icon1;
          } else if (
            documentType.startsWith(
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            )
          ) {
            icon = icon1;
          }

          return {
            link: `${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${attachment.content.id}/view`,
            text: attachment.name,
            thumbnail: icon,
          };
        })
    );
  };
  useEffect(() => {
    const saveFormValuesToSessionStorage = () => {
      sessionStorage.setItem("formValues", JSON.stringify(formValues));
    };
    saveFormValuesToSessionStorage();
  }, [formValues]);

  return (
    <div>
      <Modal
        opened={isTaskModalOpen}
        onClose={() => {
          resetFormFiles();
          setTaskModalOpen(false);
          navigate(`/orders/${orderId}`);
          resetOneTask();
        }}
        withCloseButton={true}
        radius="md"
        title={OneTask?.form.name}
        size="lg"
        centered
      >
        <Container>
          <Title order={6}>
            {OneTask?.form.description && (
              <TypographyStylesProvider>
                <div
                  dangerouslySetInnerHTML={{
                    __html: OneTask?.form.description,
                  }}
                />
              </TypographyStylesProvider>
            )}
          </Title>
          <Text c="red">{OneTask?.form.warning}</Text>
          {OneTask?.form.attachments &&
            OneTask?.form.attachments.some(
              (att) => att.content.type !== "image/jpeg"
            ) && <MySlider content={documents(OneTask?.form)} type={"doc"} />}
          <form onSubmit={handleSubmit}>
            {OneTask?.form?.fields &&
              Array.isArray(OneTask.form.fields) &&
              OneTask.form.fields.map((field: any) => (
                <React.Fragment key={field.id}>
                  {renderFormField(field)}
                </React.Fragment>
              ))}
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                gap: "15px",
              }}
            >
              <Button
                style={{ marginTop: 10 }}
                type="submit"
                radius="md"
                loading={isLoading}
                disabled={Object.values(isFileLoading).some((value) => value)}
              >
                Отправить
              </Button>
              <Text
                variant="gradient"
                gradient={{ from: "red", to: "orange", deg: 167 }}
                fw={700}
              >
                {taskError}
              </Text>
            </div>
          </form>
        </Container>
      </Modal>
    </div>
  );
};
export default TaskModal;
